<template lang="pug">
	#app(:class="appClass")
		router-view
</template>

<script>
export default {
  name: 'AdminArea',
  computed: {
    appClass() {
      return this.$route.meta.appClass || '';
    },
  },
};
</script>

<style>
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  height: 100%;
}
</style>
