<template lang="pug">
b-row
  .col-md
    .form-group
      label Creative
      div
        router-link(
          :to='{ name: "users.view", params: { id: model.creative._id } }'
        ) {{ model.creative.fullName }}

    .form-group
      label Contest
      div
        router-link(
          :to='{ name: "contests.view", params: { id: model.contest._id } }'
        ) {{ model.contest.title }}

    .form-group(v-if='model.name')
      label Name
      div "{{ model.name }}"

    .form-group(v-if='model.name')
      label Domain
      div "{{ model.domain }}"

    .form-group(v-if='model.tagline')
      label Tagline
      div "{{ model.tagline }}"

    .form-group(v-if='model.file')
      label Attachment
      div
        a(:href='model.file.dwUrl') {{ model.file.fileName }}

    .form-group(v-if='model.thumbnail')
      label Thumbnail
      div
        img(:src='model.thumbnail.dwUrl', :width='240')

  .col-md
    .form-group(v-if='model.messages.length > 0')
      label Messages
      div
        i {{ model.messages[0].text }}
    b-form-group(label-for='notes', label='Notes')
      b-form-textarea#notes(
        v-model='model.notes',
        required,
        placeholder='Enter a note...',
        rows='3',
        max-rows='6'
      )
</template>

<script>
import _pick from 'lodash/pick';

const MODEL_FIELDS = ['notes'];

export default {
  name: 'EntryForm',
  components: {},

  props: ['model'],

  mounted() {
    this.isLoading = true;
  },

  methods: {
    validate() {
      return this.$validator
        .validateAll()
        .then(() => _pick(this.model, MODEL_FIELDS))
        .catch(() => false);
    },
  },
};
</script>

<style scoped>
select {
  overflow-y: hidden;
}
</style>
