<template lang="pug">
  #entries
    router-view

    .view(v-if="showList")
      h2.mb-0.mt-1.mb-4 Entries
      .card
        .card-header
          .row
            .col-7
              b-button-toolbar
                b-button( variant="outline-primary", @click="selectAllRows", class="mr-2") Select all
                b-button( variant="outline-primary", @click="clearSelected", class="mr-2", :disabled="selectedEntries.length===0") Clear selected
                b-button( variant="success", @click="publishSelected", class="mr-2", :disabled="selectedEntries.length===0")
                  i.fa.fa-paper-plane.mr-2
                  span Publish
                b-button( variant="danger", @click="declineSelected", class="mr-2", :disabled="selectedEntries.length===0")
                  i.fa.fa-ban.mr-2
                  span Decline
            .col-3
              input.form-control(type="text",
                @keydown.enter.prevent="refresh",
                placeholder="search...",
                v-model="searchText")
            .col-2
              select.form-control(name="status",
                @change="refresh",
                v-model="searchStatus")
                option(value="") All statuses
                option(value="pending") Pending
                option(value="active") Active
                option(value="rejected") Rejected
                option(value="frontrunner") Front runner
                option(value="winner") Winner
                option(value="declined") Declined

        .card-body.p-0
          b-table(id="entries-table", selectable, hover, :items="list", :fields="fields", ref="entriesTable",
          :busy.sync="isLoading", @row-selected="onRowSelected", per-page=0, :filter="filter",
           :no-local-sorting="true", :filterIncludedFields="filterOn", :sort-by.sync="sortBy", :sort-desc.sync="sortDesc", responsive)
            template(v-slot:cell(createdAt)="data")
              router-link(:to="{name: 'entries.view', params: { id: data.item._id }}") {{ data.value | moment("YYYY-MM-DD HH:mm:ss") }}
              .small ID: {{ data.item._id }}
            template(v-slot:cell(creative)="data")
              router-link(v-if="data.item.creative" :to="{name: 'users.view', params: { id: data.item.creative._id }}") {{ data.item.creative.userName }}
            template(v-slot:cell(contest)="data")
              router-link(v-if="data.item.contest" :to="{name: 'contests.view', params: { id: data.item.contest._id }}") {{ data.item.contest.title }}
              span(v-else) {{ data.item.contestTitle }}
            template(v-slot:cell(thumbnail)="data")
              img(v-if="data.value", :src="data.value.dwUrl", width=120)
            template(v-slot:cell(status)="data")
              span.badge(:class="`badge-${entryStatusClass(data.value)}`") {{ data.value }}
            template(v-slot:cell(actions)="data")
              div.tb-column
                button.btn.btn-danger.btn-sm(v-if="data.item.status !=='declined'", :disabled="isLoading", @click="declineEntry(data.item)", title="Decline entry")
                  i.fa.fa-ban
                button.btn.btn-danger.btn-sm.ml-1(:disabled="isLoading", @click="deleteEntry(data.item)", title="Permanently delete entry")
                  i.fa.fa-trash
                button.btn.btn-success.btn-sm.ml-1(:disabled="isLoading", @click="publishEntry(data.item)", v-if="data.item.status === 'pending' || data.item.status === 'declined'", title="Publish entry")
                  i.fa.fa-paper-plane
                router-link(:to="{name: 'entries.view', params: { id: data.item._id }}", class="btn btn-primary btn-sm ml-1", title="View entry")
                  i.fa.fa-eye
        .card-footer(class="text-right")
          b-form(inline)
            //- span(class="mr-1") Per page:
            //- b-form-select(v-model="perPage",  :options="[10, 25, 50]", class="mt-0 mr-2")
            b-pagination-nav(
              v-model="currentPage"
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
              @input="refresh()"
              class="mb-0 mr-3"
              aria-controls="entries-table")

            span(class="mb-3") {{ totalResults }} Total Results
</template>

<script>
import EntryStatusClass from '../mixins/entry-status-class';

export default {
  name: 'EntriesPage',
  mixins: [EntryStatusClass],

  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('SET_LOADER_STATE', false);
    next();
  },

  data: () => ({
    list: [],

    perPage: 50,
    currentPage: 1,
    sortBy: 'createdAt',
    sortDesc: true,
    numberOfPages: 1,
    totalResults: 0,

    searchText: '',
    searchStatus: '',
    isLoading: false,
    filter: null,
    filterOn: [],
    selectedEntries: [],
    fields: [
      {
        key: 'createdAt',
        label: 'Created At',
        sortable: true,
      },
      {
        key: 'creative',
        label: 'Creative',
        sortable: true,
      },
      {
        key: 'type',
        sortable: true,
        formatter: value => value.replace('Entry', '').toLowerCase(),
      },
      { key: 'name', sortable: true },
      { key: 'domain', sortable: true },
      { key: 'tagline', sortable: true },
      {
        ey: 'thumbnail',
        lbel: 'Attachment',
      },
      {
        key: 'contest',
        label: 'Contest',
        sortable: true,
      },
      {
        key: 'notes',
        label: 'Note',
      },
      { key: 'rating', sortable: true },
      { key: 'status', sortable: true },
      { key: 'actions', thStyle: 'width: 150px', tdClass: 'tb-column' },
    ],
  }),
  url: {
    searchText: 'search',
    searchStatus: 'status',
  },

  computed: {
    showList() {
      return this.$route.name === 'entries';
    },
  },

  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
    sortBy() {
      this.refresh();
    },
    sortDesc() {
      this.refresh();
    },
  },

  mounted() {
    if (this.$route.query.page) {
      this.currentPage = Number.parseInt(this.$route.query.page, 10) || 1;
    }
    if (this.showList) this.refresh();
    this.$store.dispatch('SET_LOADER_STATE', false);
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    selectAllRows() {
      this.$refs.entriesTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.entriesTable.clearSelected();
    },
    publishSelected() {
      this.$bvModal
        .msgBoxConfirm(`Publish selected entries`, { centered: true })
        .then(async res => {
          if (!res) return;

          this.isLoading = true;
          const updates = this.selectedEntries.map(entry =>
            this.$http
              .put(`v1/admin/entries/${entry._id}`, { status: 'active' })
              .catch(error => {
                this.$toast.error(error.message);
                console.error(entry._id, error);
              }),
          );

          await Promise.all(updates);

          this.isLoading = false;
          this.refresh();
        });
    },
    declineSelected() {
      this.$bvModal
        .msgBoxConfirm(`Decline selected entries`, { centered: true })
        .then(async res => {
          if (!res) return;

          this.isLoading = true;
          const updates = this.selectedEntries.map(entry =>
            this.$http
              .put(`v1/admin/entries/${entry._id}`, { status: 'declined' })
              .catch(error => {
                this.$toast.error(error.message);
                console.error(entry._id, error);
              }),
          );

          await Promise.all(updates);

          this.isLoading = false;
          this.refresh();
        });
    },
    onRowSelected(items) {
      this.selectedEntries = items;
    },
    newEntry() {
      this.$router.push({ name: 'entries.new' });
    },

    publishEntry(entry) {
      this.$bvModal
        .msgBoxConfirm(`Publish this entry`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .put(`v1/admin/entries/${entry._id}`, { status: 'active' })
            .then(() => {
              this.$toast.success(
                `Entry ${entry._id} was successfully published.`,
              );
              this.refresh();
            })
            .catch(error => {
              this.$toast.error(error.message);
              this.isLoading = false;
            });
        });
    },

    declineEntry(entry) {
      this.$bvModal
        .msgBoxConfirm(`Decline this entry`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .put(`v1/admin/entries/${entry._id}`, { status: 'declined' })
            .then(() => {
              this.$toast.success(
                `Entry ${entry._id} was successfully declined.`,
              );
              this.refresh();
            })
            .catch(error => {
              this.$toast.error(error.message);
              this.isLoading = false;
            });
        });
    },

    deleteEntry(entry) {
      this.$bvModal
        .msgBoxConfirm(`Delete this entry`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/entries/${entry._id}`)
            .then(() => {
              this.$toast.success(
                `Entry ${entry._id} was successfully deleted.`,
              );
              this.refresh();
            })
            .catch(error => {
              this.$toast.error(error.message);
              this.isLoading = false;
            });
        });
    },

    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/entries', {
          params: {
            search: this.searchText,
            status: this.searchStatus,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc,
            page: this.currentPage,
            limit: this.perPage,
          },
        })
        .then(result => {
          this.list = result.items;
          this.numberOfPages = Math.floor(result.count / this.perPage + 1);
          this.isLoading = false;
          this.totalResults = result.count;
        })
        .catch(error => {
          this.$toast.error(error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.tb-column {
  width: 135px;
}
</style>
