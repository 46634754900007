<template lang="pug">
	.row
		.col-md
			.card
				slide-loader(:display="isLoading")
				.card-header
					span New User
				.card-body
					user-form(:model="user", ref="form")

				.card-footer.text-right
					button.btn.btn-success(:disabled="isLoading", @click="save") Save
</template>

<script>
import UserForm from './UserForm.vue';

export default {
  name: 'UserNew',
  components: { UserForm },

  data: () => ({
    user: {
      fullName: '',
      email: '',
      password: '',
      roles: [],
    },
    isLoading: false,
  }),

  mounted() {
    this.$store.dispatch('SET_LOADER_STATE', false);
  },

  methods: {
    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post('v1/admin/users', model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'New user succesful created.');
          this.$router.push({
            name: 'users',
          });
        })
        .catch(error => {
          this.$toast.error('User save error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
