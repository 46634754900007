const EntriesPage = (r) => require.ensure([], () => r(require('./EntriesPage.vue')), 'entries')
const EntryView = (r) => require.ensure([], () => r(require('./EntryView.vue')), 'entries')
// const EntryNew = r => require.ensure([], () => r(require('./EntryNew.vue')), 'entries');

export const routes = [
  {
    path: '/entries',
    name: 'entries',
    component: EntriesPage,
    meta: {
      title: 'Entries',
      view: 'panel'
    },
    children: [
      /* {
		path: 'new',
		name: 'entries.new',
		component: EntryNew,
		meta: {
			title: 'New',
			view: 'panel'
		}
	}, */ {
        path: ':id',
        name: 'entries.view',
        component: EntryView,
        meta: {
          title: 'View',
          view: 'panel'
        }
      }
    ]
  }
]
