<template lang="pug">
	#item_view
		.row
			.col-md
				.row
					.col-12
						.card
							slide-loader(:display="isLoading")
							.card-body(v-show="item")
								item-form(:model="item", ref="form")
							.card-body(v-show="!item")
								.text-center.mt-2.mb-2 Loading...
							.card-footer.text-right(v-if="item")
								button.btn.btn-danger.mr-2(:disabled="isLoading", @click="remove") Remove
								router-link(:to="{name: 'discounts'}") Cancel
								button.btn.btn-info.ml-2(:disabled="isLoading", @click="save") Save
</template>

<script>
import form from './form';

export default {
  components: { 'item-form': form },
  data: () => ({
    isLoading: false,
    item: {},
  }),

  mounted() {
    this.$on('refresh', this.refresh.bind(this));
    this.$store.dispatch('SET_LOADER_STATE', false);
    this.refresh();
  },
  methods: {
    remove() {
      this.$bvModal
        .msgBoxConfirm(`Delete this item`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete('v1/admin/discounts/' + this.item._id)
            .then(() => {
              this.$toast.success(
                'Complete',
                `.${this.item.name} successfully removed.`,
              );
              this.$router.push({ name: 'discounts' });
            })
            .catch(error => {
              this.$toast.error('Item delete error', error.message);
              this.isLoading = false;
            });
        });
    },

    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .put(`v1/admin/discounts/${this.item._id}`, model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'Item succesfully updated.');
          this.$router.push({
            name: 'discounts',
          });
        })
        .catch(error => {
          this.$toast.error('Item save error', error.message);
          this.isLoading = false;
        });
    },

    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/discounts/' + this.$route.params.id)
        .then(result => {
          this.item = result;
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('Item fetch error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
