<template lang="pug">
  #payouts
    router-view
        b-card.mt-3(title="Payoneer Status")

    .view(v-if="showList")
      h2.mb-0.mt-1.mb-4 Payouts
      p(v-if="!!payoneerBalance") Payoneer balance: ${{payoneerBalance.balance}}; Fees due: ${{payoneerBalance.fees_due}}
      .card
        slide-loader(:display="isLoading")
        .card-header
          .row
            .col-3
              date-range-picker(
                  :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="true"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  @update="refresh"
                  v-model="reportsRanges.payouts")
            .col-2
              select.form-control.ml-3(
                name='state',
                @change='filter()',
                v-model='filterBy.state'
              )
                option(:value='null', selected='true') All states
                option(value='declined') Declined
                option(value='approved') Approved
                option(value='applied') Confirmed by Payoneer
                option(value='waiting_for_confirmation') Waiting for Payoneer's confirmation
                option(value='cancelled') Cancelled on Payoneer
                option(value='pending') Pending
        .card-body.p-0
          table.table
            thead
              tr
                th Date
                th State
                th Amount
                th User
                th Contests
                th Details
                th Actions
            tbody
              tr(v-for="(row, index) in list")
                td {{ row.date }}
                td {{ row.state }}
                td.text-right ${{ Math.abs(row.amount) / 100 }}
                td
                  router-link(v-if="!!row.account", :to="{name: 'users.view', params: { id: row.account._id }}") {{ row.account.fullName }} ({{ row.account.email }})
                td
                  span(v-for="(topup, index) in row.topups")
                    router-link(v-if="!!topup.metadata.contest", :to="{name: 'contests.view', params: { id: topup.metadata.contest._id }}") \#{{ topup.metadata.contest.number }}
                    br
                td {{ row.metadata.reason_display }}: {{ row.metadata.reason_actual }}
                td.text-right(width="200px")
                  button.btn.btn-danger.btn-sm(v-if="row.state ==='pending'", :disabled="isLoading", @click="declinePayout(row)", title="Decline payout")
                    i.fa.fa-ban
                  button.btn.btn-danger.btn-sm.ml-1(v-if="advancedMode", :disabled="isLoading", @click="confirmPayout(row)", title="Confirm payout")
                    i.fa.fa-check
                  button.btn.btn-success.btn-sm.ml-1(:disabled="isLoading", @click="approvePayout(row)", v-if="row.state === 'pending'", title="Approve payout")
                    i.fa.fa-paper-plane
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'TLDsPage',
  components: { DateRangePicker },
  data: () => ({
    list: [],
    displayList: [],
    searchText: '',
    isLoading: false,
    payoneerBalance: null,
    filterBy: {
      state: null,
    },
    reportsRanges: {
      payoutsPerDay: {
        startDate: moment().startOf('month').hour(12).toDate(),
        endDate: moment().endOf('month').hour(12).toDate(),
      },
      payouts: {
        startDate: moment().startOf('year').hour(12).toDate(),
        endDate: moment().endOf('month').hour(12).toDate(),
      },
    },
  }),
  computed: {
    advancedMode() {
      return this.$route.query.advanced === 'true';
    },
    showList() {
      return this.$route.name === 'payouts';
    },
  },
  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
  },
  mounted() {
    if (this.showList) {
      this.refresh();
      this.getBalance();
    }
    this.$store.dispatch('SET_LOADER_STATE', false);
  },
  methods: {
    async getBalance() {
      const result = await this.$http.get('v1/admin/reports/payoneer-balance');

      this.payoneerBalance = result;
    },
    priceFormat(val) {
      return '$' + val / 100;
    },
    filter() {
      const context = this;

      Vue.nextTick(() => {
        context.refresh();
      });
    },
    declinePayout(payout) {
      this.isLoading = true;

      this.$http
        .put(`v1/admin/payouts/${payout._id}`, { state: 'declined' })
        .then(result => {
          this.refresh();
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('Fetching error', error.message);
          this.isLoading = false;
        });
    },
    approvePayout(payout) {
      this.isLoading = true;

      this.$http
        .put(`v1/admin/payouts/${payout._id}`, { state: 'approved' })
        .then(result => {
          this.refresh();
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('Fetching error', error.message);
          this.isLoading = false;
        });
    },
    confirmPayout(payout) {
      this.isLoading = true;

      this.$http
        .put(`v1/admin/payouts/${payout._id}`, { state: 'applied' })
        .then(result => {
          this.refresh();
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('Fetching error', error.message);
          this.isLoading = false;
        });
    },
    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/payouts', {
          params: {
            from: this.reportsRanges.payouts.startDate,
            until: this.reportsRanges.payouts.endDate,
            state: this.filterBy.state,
          },
        })
        .then(result => {
          this.list = result;
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('Fetching error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
