<template lang="pug">
  form
    .row
      .col-12
        .form-group
          label Code
            b-overlay(
              :show="busyGeneratingCode"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
              @hidden="onHidden")
              b-button.ml-2(@click="generateCode", ref="generateCodeButton", :disabled="busyGeneratingCode", variant="link") Generate one
          input.form-control(placeholder="", name="code", v-model="model.code", v-validate="'required'")
          .mt-2.text-danger(v-if="errors.has('code')")
            span {{ errors.first('code') }}

        .form-group
          label Amount
          input.form-control(placeholder="", type="number", name="amount", v-model="model.amount", v-validate="'required'")
          small.form-text.text-muted The discount amount is either a specific dollar amount or a percentage that will be taken off of the order's total.
          .mt-2.text-danger(v-if="errors.has('amount')")
            span {{ errors.first('amount') }}

        .form-group
          label Usage Limit
          input.form-control(placeholder="", type="number", name="usageLimit", v-model="model.usageLimit", v-validate="'required'")
          small.form-text.text-muted How many times this code can be used.
          .mt-2.text-danger(v-if="errors.has('usageLimit')")
            span {{ errors.first('usageLimit') }}

        .form-group
          b-form-checkbox(v-model="model.isPercent" name="isPercent" switch) Percentage?
</template>

<script>
import _pick from 'lodash/pick';

const MODEL_FIELDS = ['code', 'isPercent', 'usageLimit', 'amount'];

export default {
  name: 'DiscountCodeForm',
  props: ['model'],

  data() {
    return {
      busyGeneratingCode: false,
    };
  },

  methods: {
    validate() {
      return this.$validator
        .validateAll()
        .then(() => _pick(this.model, MODEL_FIELDS))
        .catch(() => false);
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.generateCodeButton.focus();
    },
    generateCode() {
      this.busyGeneratingCode = true;
      this.$http
        .post('v1/admin/discounts/codes')
        .then(result => {
          this.model.code = result;
        })
        .catch(error => {
          this.$toast.error('Error', error.message);
        })
        .finally(() => {
          this.busyGeneratingCode = false;
        });
    },
  },
};
</script>
