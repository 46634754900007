const IndustriesPage = r => require.ensure([], () => r(require('./IndustriesPage.vue')), 'industries')
const IndustryView = r => require.ensure([], () => r(require('./IndustryView.vue')), 'industries')
const IndustryNew = r => require.ensure([], () => r(require('./IndustryNew.vue')), 'industries')

export const routes = [{
  path: '/industries',
  name: 'industries',
  component: IndustriesPage,
  meta: {
    title: 'industries',
    view: 'panel'
  },
  children: [{
    path: 'new',
    name: 'industries.new',
    component: IndustryNew,
    meta: {
      title: 'New',
      view: 'panel'
    }
  }, {
    path: ':id',
    name: 'industries.view',
    component: IndustryView,
    meta: {
      title: 'View',
      view: 'panel'
    }
  }, {
    path: 'import',
    name: 'industries.import',
    component: IndustriesPage,
    meta: {
      title: 'Import',
      view: 'panel'
    }
  }]
}]
