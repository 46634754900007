import _set from 'lodash/set';

import DEFAULT from './default';

export function SET(state, [path, value]) {
  _set(state, path, value);
}

export function SET_TOKEN(state, token) {
  state.token = token;
  state.auth = !!token;
}

export function SET_ACCOUNT(state, account) {
  state.account = account;
}

export function PUSH_AREAS(state, list) {
  state.account.areas.push(...list);
}

export function LOGOUT(state) {
  for (const key of Object.keys(DEFAULT)) {
    state[key] = DEFAULT[key];
  }

  state.account = JSON.parse(JSON.stringify(DEFAULT.account));
}

export function SET_LOADER_STATE(state, value) {
  state.loader = !!value;
}
