<template lang="pug">
  b-container#user_view
    slide-loader(:display="isLoading")
    b-row
      b-col
        b-card(v-if="user")
          user-form(:model="user", ref="form", @dataChanged="refresh")

          template(v-slot:header)
            a.btn.btn-outline-primary.mr-3(:disabled="isLoading", :href="impersonateUrl()", target="_blank")
              i.fa.fa-user-secret
              span  Impersonate
            b-button.mr-3(variant="outline-primary", v-if="!user.disabledAt && !user.suspendedAt", :disabled="isLoading", @click="closeAccount(user)", title="Close account")
              i.fa.fa-trash.mr-2
              span Close account
            b-button.mr-3(variant="outline-primary", v-if="!user.disabledAt && !user.suspendedAt",:disabled="isLoading", @click="suspendAccount(user)", title="Suspend account")
              i.fa.fa-ban.mr-2
              span Suspend account
            b-button.mr-3(variant="outline-primary", v-if="user.disabledAt || user.suspendedAt",:disabled="isLoading", @click="restoreAccount(user)", title="Restore account")
              i.fa.fa-unlock.mr-2
              span Restore account
            b-button.mr-3(variant="outline-primary", @click="exportLogsCSV")
              i.fa.fa-file-excel-o.mr-2
              span Download Logs
            b-button(variant="outline-primary", @click="exportEntriesCSV")
              i.fa.fa-file-excel-o.mr-2
              span Export entries
            button.btn.btn-info(:disabled="isLoading", @click="save", style="float: right") Save
</template>

<script>
import UserForm from './UserForm';

export default {
  name: 'UserView',
  components: { UserForm },

  data: () => ({
    isLoading: false,
    user: {
      roles: [],
      billingInfo: {},
    },
  }),

  computed: {
    userId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.$on('refresh', this.refresh.bind(this));
    this.$store.dispatch('SET_LOADER_STATE', false);
    this.refresh();
  },

  methods: {
    impersonateUrl() {
      return this.$clientAppUrl + 'login?as_user=' + this.user.apikey;
    },
    suspendAccount(user) {
      this.$bvModal
        .msgBoxConfirm(`Suspend this account`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/users/${user._id}?action=suspend`)
            .then(() => {
              this.$toast.success('Complete', `Account suspended.`);
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.isLoading = false;
            });
        });
    },

    closeAccount(user) {
      this.$bvModal
        .msgBoxConfirm(`Close this account`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/users/${user._id}?action=disable`)
            .then(() => {
              this.$toast.success('Complete', `Account closed.`);
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.isLoading = false;
            });
        });
    },

    restoreAccount(user) {
      this.$bvModal
        .msgBoxConfirm(`Restore this account`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .post(`v1/admin/users/${user._id}/restore`)
            .then(() => {
              this.$toast.success('Complete', `Account restored.`);
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.isLoading = false;
            });
        });
    },

    exportLogsCSV() {
      return this.downloadFile(
        'v1/admin/users/' + this.user._id + '/logs.csv',
        this.user._id + 'logs.csv',
      );
    },
    exportEntriesCSV() {
      return this.downloadFile(
        'v1/admin/users/' + this.user._id + '/entries.csv',
        this.user._id + 'entries.csv',
      );
    },
    remove() {
      this.$bvModal
        .msgBoxConfirm(`Delete this user`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete('v1/admin/users/' + this.user._id)
            .then(() => {
              this.$toast.success(
                'Complete',
                `${this.user.fullName} successfully removed.`,
              );
              this.$router.push({ name: 'users' });
            })
            .catch(error => {
              this.$toast.error('User delete error', error.message);
              this.isLoading = false;
            });
        });
    },

    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      /* model.roles = [];
      if (model.role) {
        model.roles[0] = model.role;
        delete model.role;
      } */

      this.isLoading = true;

      this.$http
        .put(`v1/admin/users/${this.userId}`, model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'User succesful updated.');
          this.$router.push({
            name: 'users',
          });
        })
        .catch(error => {
          this.$toast.error('User save error', error.message);
          this.isLoading = false;
        });
    },

    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/users/' + this.userId)
        .then(result => {
          result.password = '';

          result.role = '';
          if (result.roles) result.role = result.roles[0];

          this.user = result;
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('User fetching error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
