import VeeValidate, { Validator } from 'vee-validate';

const orgValidateAll = Validator.prototype.validateAll;

Validator.prototype.validateAll = function (...args) {
  return orgValidateAll.apply(this, args).then(result => {
    if (!result) {
      const err = new Error('Form invalid') as any;
      err.validation = true;

      throw err;
    }

    return result;
  });
};

export default VeeValidate;
