const LogsPage = r => require.ensure([], () => r(require('./LogsPage.vue')), 'logs')

export const routes = [{
  path: '/logs',
  name: 'logs',
  component: LogsPage,
  meta: {
    title: 'Logs',
    view: 'panel'
  }
}]
