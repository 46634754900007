<template>
  <transition
    name="slide"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
  >
    <div v-if="isLoading" :class="loaderClass" />
  </transition>
</template>

<script>
export default {
  name: 'Loader',
  data: () => ({
    animEnd: false,
  }),
  computed: {
    loaderClass() {
      return 'loader' + (this.animEnd ? ' slide-wait' : '');
    },
    isLoading() {
      return this.$store.state.loader;
    },
  },
  created() {
    this.$root.$on('loader:show', this.showLoader.bind(this));
    this.$root.$on('loader:hide', this.hideLoader.bind(this));
  },
  methods: {
    afterEnter() {
      this.animEnd = true;
    },
    beforeLeave() {
      this.animEnd = false;
    },
    toggleSidebar() {
      this.$parent.$emit('sidebar:toggle');
    },
    toggleLoader() {
      this.$store.commit('SET_LOADER_STATE', !this.isLoading);
    },
    showLoader() {
      this.$store.commit('SET_LOADER_STATE', true);
    },
    hideLoader() {
      this.$store.commit('SET_LOADER_STATE', false);
    },
  },
};
</script>

<style scoped lang="scss">
.slide-enter-active {
  transition: transform 3s, opacity 0.3s;
  transform: translateX(-100%);
  opacity: 0;
}

.slide-leave-active {
  transition: transform 1s, opacity 1.3s;
  transform: translateX(-50%);
  opacity: 0;
}

.slide-enter-to,
.slide-wait {
  transform: translateX(-50%);
  opacity: 1;
}

.slide-leave-to {
  transform: translateX(0%);
  opacity: 0;
}

.loader {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background-color: #fafff2;
  width: 100%;
}
</style>
