














































































































































































































































































































































































































































































































































































































































































































































































import _pick from 'lodash/pick';
import EntryStatusClass from '../mixins/entry-status-class';

const MODEL_FIELDS = [
  'prizeAmount',
  'title',
  'industry',
  'ventureDescription',
  'competitiveAdvantage',
  'relevantDetails',
  'information',
  'showPremium',
  'liveAt',
  'attachmentsToUpload',
];

export default {
  name: 'ContestForm',
  components: {},
  mixins: [EntryStatusClass],
  props: ['model', 'entries'],

  data() {
    return {
      addNewEntryForm: {
        creativeId: '',
        name: '',
        domain: '',
      },
      isLoading: false,
      advanced: false,
      industries: [''],
      hasWinnerMarked: false,
      contestToMoveEntries: '',
      contestToMoveEntriesState: null,
      generatedEntries: '',
      generatedEntriesPrompt: '',
      generatedEntriesState: null,

      typesOfName: [
        { id: 'company', text: 'Company' },
        { id: 'product', text: 'Product' },
        { id: 'project', text: 'Project' },
      ],

      domainNames: [
        { id: 'matching', text: 'Matching' },
        { id: 'variations', text: 'Variations' },
        { id: 'open', text: "I'm Open" },
      ],

      domainTopLevels: [
        { id: 'com', text: '.com Only' },
        { id: 'other', text: 'Other Popular Extensions' },
        { id: 'open', text: 'Open to anything' },
      ],

      companyDescriptions: [
        { id: 'sincere', text: 'Sincere' },
        { id: 'exciting', text: 'Exciting' },
        { id: 'sophisticated', text: 'Sophisticated' },
        { id: 'competent', text: 'Competent' },
      ],

      nameLengthPreferences: [
        { id: 'shorter', text: 'Shorter' },
        { id: 'longer', text: 'Longer' },
        { id: 'open', text: "I'm Open" },
      ],

      // multiselect
      nameTypePreferences: [
        { id: 'real_word', text: 'Real Word' },
        { id: 'misspelled', text: 'Misspelled' },
        { id: 'made_up', text: 'Made Up' },
        { id: 'blends', text: 'Blends' },
        { id: 'phrases', text: 'Phrases' },
        { id: 'compound', text: 'Compound' },
        { id: 'acronyms', text: 'Acronyms' },
        { id: 'play_on_words', text: 'Play On Words' },
        { id: 'open', text: "I'm Open" },
      ],

      // multiselect
      nameStylePreferences: [
        { id: 'classic', text: 'Classic' },
        { id: 'fun', text: 'Fun' },
        { id: 'professional', text: 'Professional' },
        { id: 'descriptive', text: 'Descriptive' },
        { id: 'youthful', text: 'Youthful' },
        { id: 'open', text: "I'm Open" },
      ],

      taglineTypePreferences: [
        { id: 'classic', text: 'Classic' },
        { id: 'fun', text: 'Fun' },
        { id: 'powerful', text: 'Powerful' },
        { id: 'descriptive', text: 'Descriptive' },
        { id: 'modern', text: 'Modern' },
        { id: 'open', text: "I'm Open" },
      ],

      visualStylePreferences: [
        { id: 'techy', text: 'techy' },
        { id: 'fun', text: 'fun' },
        { id: 'fancy', text: 'fancy' },
        { id: 'minimal', text: 'minimal' },
        { id: 'brick_and_mortar', text: 'brick and mortar' },
        { id: 'photo_based', text: 'photo based' },
      ],

      visualColorPreferences: [
        { id: 'black', text: 'black' },
        { id: 'blue', text: 'blue' },
        { id: 'yellow', text: 'yellow' },
        { id: 'green', text: 'green' },
        { id: 'purple', text: 'purple' },
        { id: 'pink', text: 'pink' },
        { id: 'red', text: 'red' },
        { id: 'orange', text: 'orange' },
        { id: 'brown', text: 'brown' },
      ],

      visualFontPreferences: [
        { id: 'serif', text: 'serif' },
        { id: 'slab_serif', text: 'slab serif' },
        { id: 'sans_serif', text: 'sans serif' },
        { id: 'script', text: 'script' },
        { id: 'blackletter', text: 'blackletter' },
        { id: 'display', text: 'display' },
      ],

      entriesTable: {
        sortBy: 'createdAt',
        sortDesc: true,
        fields: [
          {
            key: 'createdAt',
            label: 'Date',
            sortable: true,
          },
          {
            key: 'creative',
            label: 'Creative',
            sortable: true,
            formatter: (value, key, item) => item.creative.fullName,
            sortByFormatted: true,
          },
          { key: 'entry', sortable: false },
          {
            key: 'inTop10',
            label: 'In Top 10',
            sortable: true,
            sortByFormatted: true,
            formatter: (value, key, item) => (item.isFavorite ? 'Yes' : 'No'),
          },
          { key: 'rating', sortable: true },
          { key: 'status', sortable: true },
          { key: 'actions', thStyle: 'width: 150px', tdClass: 'tb-column' },
        ],
        list: [],
      },
    };
  },

  computed: {
    prizeAmount: {
      set(v) {
        this.model.prizeAmount = v;
      },
      get() {
        return this.model.prizeAmount;
      },
    },

    describeVentureWords: {
      set(v) {
        this.model.describeVentureWords = v.split(',');
      },
      get() {
        return this.model.describeVentureWords.join(',');
      },
    },
  },

  mounted() {
    if (typeof this.$route.query.advanced !== 'undefined') {
      this.advanced = true;
    }
    this.isLoading = true;
    this.$http
      .get('v1/admin/industries')
      .then(result => {
        this.isLoading = false;
        this.industries = result;
      })
      .catch(error => {
        this.$toast.error('Industries fetch error', error.message);
        this.isLoading = false;
      });
  },

  methods: {
    resetModal() {
      this.contestToMoveEntries = '';
      this.contestToMoveEntriesState = null;
      this.generatedEntries = '';
      this.generatedEntriesState = null;
      this.generatedEntriesPrompt = '';
    },
    validate() {
      return this.$validator
        .validateAll()
        .then(() => _pick(this.model, MODEL_FIELDS))
        .catch(() => false);
    },

    pickWinner(entry) {
      this.$bvModal
        .msgBoxConfirm(`Pick ${entry.creative.fullName} as winner?`, {
          centered: true,
        })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .post(
              `v1/admin/contests/${this.model._id}/entries/${entry._id}/winner`,
            )
            .then(result => {
              this.isLoading = false;
              this.$parent.$emit('refresh', true);
              this.$toast.success(
                'Completed',
                `Winner ${entry.creative.fullName} successfully picked.`,
              );
            })
            .catch(error => {
              this.$toast.error('Pick a winner error', error.message);
              this.isLoading = false;
              this.$parent.$emit('refresh', true);
            });
        });
    },

    addNewEntry(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault();
      }

      const valid = this.$refs.addNewEntryFormRef.checkValidity();

      if (!valid) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post(`v1/admin/contests/${this.model._id}/entries`, {
          creativeId: this.addNewEntryForm.creativeId,
          name: this.addNewEntryForm.name,
          domain: this.addNewEntryForm.domain,
        })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('add-new-entry');
          });
          this.isLoading = false;
          this.$toast.success('Complete', 'Entry successfully added');
          this.$parent.$emit('refresh', true);
        })
        .catch(error => {
          this.$toast.error('Error', error.message);
          this.isLoading = false;
        });
    },

    moveEntriesToContest(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault();
      }

      const valid = this.$refs.moveEntriesToContestForm.checkValidity();
      this.contestToMoveEntriesState = valid;

      if (!valid) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post(`v1/admin/contests/${this.model._id}/entries/move`, {
          to: this.contestToMoveEntries,
        })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('move-entries');
          });
          this.isLoading = false;
          this.$toast.success('Complete', 'Entries moved successfully');
          this.$parent.$emit('refresh', true);
        })
        .catch(error => {
          this.$toast.error('Error', error.message);
          this.isLoading = false;
        });
    },

    generateEntries() {
      this.isLoading = true;
      this.$bvModal.show('generated-entries');

      this.$http
        .post(`v1/admin/contests/${this.model._id}/entries/generate`)
        .then(({ prompts, response }) => {
          this.isLoading = false;
          this.generatedEntriesPrompt = prompts?.join(' ');
          this.generatedEntries = response?.[0]?.message?.content;
        })
        .catch(error => {
          this.$toast.error('Error', error.message);
          this.isLoading = false;
        });
    },

    undoWinnerPick(entry) {
      this.$bvModal
        .msgBoxConfirm(
          `Do you want to unpick ${entry.creative.fullName} as winner?`,
          {
            centered: true,
          },
        )
        .then(res => {
          if (!res) return;
          this.isLoading = true;
          this.$http
            .delete(
              `v1/admin/contests/${this.model._id}/entries/${entry._id}/winner`,
            )
            .then(result => {
              this.isLoading = false;
              this.$parent.$emit('refresh', true);
              this.$toast.success(
                'Completed',
                `Winner pick successfully undone.`,
              );
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.isLoading = false;
              this.$parent.$emit('refresh', true);
            });
        });
    },

    confirmWinner(entry) {
      this.isLoading = true;
      this.$http
        .post(
          `v1/admin/contests/${this.model._id}/entries/${entry._id}/confirm`,
        )
        .then(result => {
          this.isLoading = false;
          this.$parent.$emit('refresh', true);
          this.$toast.success(
            'Completed',
            `Winner <b>${entry.creative.fullName}</b> successfully confirmed.`,
          );
        })
        .catch(error => {
          this.$toast.error('Pick a winner error', error.message);
          this.isLoading = false;
          this.$parent.$emit('refresh', true);
        });
    },

    exportCSVWithEntries() {
      return this.downloadFile(
        `v1/admin/contests/${this.model._id}/entries?format=csv`,
        `entries-${this.model._id}.csv`,
      );
    },

    deleteNote(noteId) {
      this.$bvModal
        .msgBoxConfirm(`Delete entry?`, { centered: true })
        .then(res => {
          if (!res) return;
          this.isLoading = true;
          this.$http
            .delete(`v1/admin/contests/${this.model._id}/notes/${noteId}`)
            .then(result => {
              this.isLoading = false;
              this.$parent.$emit('refresh', true);
              this.$toast.success('Completed', `Note deleted.`);
            })
            .catch(error => {
              this.$toast.error('Note delete error', error.message);
              this.isLoading = false;
              this.$parent.$emit('refresh', true);
            });
        });
    },
  },
};
