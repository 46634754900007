<template lang="pug">
	form
		.row
			.col-12
				.form-group
					label Name
					input.form-control(placeholder="", name="name", v-model="model.name", v-validate="'required'")
					.mt-2.text-danger(v-if="errors.has('name')")
						span {{ errors.first('name') }}

				.form-group
					label Tag slug
					input.form-control(placeholder="", name="tag_slug", v-model="model.tag_slug", disabled)

				.form-group
					label Body Paragraph
					textarea.form-control(placeholder="", name="bodyParagraph", v-model="model.bodyParagraph", v-validate="'required'")
					.mt-2.text-danger(v-if="errors.has('bodyParagraph')")
						span {{ errors.first('bodyParagraph') }}

				.form-group
					label Meta Description
					textarea.form-control(placeholder="", name="metaDescription", v-model="model.metaDescription", v-validate="'required'")
					.mt-2.text-danger(v-if="errors.has('metaDescription')")
						span {{ errors.first('metaDescription') }}

</template>

<script>
import _pick from 'lodash/pick';

const MODEL_FIELDS = ['name', 'bodyParagraph', 'metaDescription'];

export default {
  name: 'IndustryForm',
  props: ['model'],
  methods: {
    validate() {
      return this.$validator
        .validateAll()
        .then(() => _pick(this.model, MODEL_FIELDS))
        .catch(() => false);
    },
  },
};
</script>
