const ContestsPage = (r) => require.ensure([], () => r(require('./ContestsPage.vue')), 'contests')
const ContestView = (r) => require.ensure([], () => r(require('./ContestView.vue')), 'contests')

export const routes = [
  {
    path: '/contests',
    name: 'contests',
    component: ContestsPage,
    meta: {
      title: 'Contests',
      view: 'panel'
    },
    children: [{
      path: ':id',
      name: 'contests.view',
      component: ContestView,
      meta: {
        title: 'View',
        view: 'panel'
      }
    }
    ]
  }
]
