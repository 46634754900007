<template lang="pug">
  #discounts
    router-view

    .view(v-if="showList")
      h2.mb-0.mt-1.mb-4 Discounts
      .card
        slide-loader(:display="isLoading")
        .card-header
          .row
            .col-8
              button.btn.btn-success(@click="newItem") New
            .col-4
        .card-body.p-0
          table.table
            thead
              tr
                th Code
                th(style='text-align: center') Amount
                th(style='text-align: center') Usage
                th
            tbody
              tr(v-for="item in displayList", :key="item._id")
                td
                  router-link(:to="{name: 'discounts.view', params: { id: item._id }}") {{ item.code }}
                td(style='text-align: center')
                  span {{ item.amount }}{{ item.isPercentage ? '%' : ''}}
                td(style='text-align: center')
                  span {{item.used}}/{{ item.usageLimit }}
                td.text-right
                  button.btn.btn-danger.btn-sm(:disabled="isLoading", @click="removeOne(item)")
                    i.fa.fa-trash
                  router-link(:to="{name: 'discounts.view', params: { id: item._id }}", class="btn btn-primary btn-sm ml-1", title="View")
                    i.fa.fa-pencil

        .card-footer
          local-pagination(:source-list="list", v-model="displayList")
</template>

<script>
import LocalPagination from '../components/LocalPagination';

export default {
  name: 'TLDsPage',
  components: { LocalPagination },
  data: () => ({
    list: [],
    displayList: [],
    searchText: '',
    isLoading: false,
  }),
  computed: {
    showList() {
      return this.$route.name === 'discounts';
    },
  },
  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
  },
  mounted() {
    if (this.showList) this.refresh();
    this.$store.dispatch('SET_LOADER_STATE', false);
  },
  methods: {
    priceFormat(val) {
      return '$' + val / 100;
    },
    newItem() {
      this.$router.push({ name: 'discounts.new' });
    },
    removeOne(discount) {
      this.$bvModal
        .msgBoxConfirm(`Delete this item`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/discounts/${discount._id}`)
            .then(() => {
              this.$toast.success(
                'Complete',
                `.${discount.name} successfully removed.`,
              );
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Item delete error', error.message);
              this.isLoading = false;
            });
        });
    },
    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/discounts')
        .then(result => {
          this.list = result;
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('TLD list fetching error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
