<template lang="pug">
b-container#contest_view(fluid)
  slide-loader(:display='isLoading')
  b-row(v-if='contest')
    b-col
      h4 {{ contests[contest.type] }} \#{{ contest.number }}
      .form-group
        span.badge.badge-success.mr-2(v-if='contest.isPaid') paid
        span.badge.badge-warning.mr-2(v-if='!contest.isPaid') not paid
        span.badge.mr-2(:class='`badge-${contestStatusClass(contest.status)}`') {{ contest.status }}
        span.badge.badge-light(v-if='contest.round') {{ contest.round.label }}
  b-row
    b-col
      b-modal#start-round-c(
        title='Start Round C',
        @show='resetModal',
        @hidden='resetModal',
        @ok='startRoundC'
      )
        form(ref='startRoundCForm', @submit.stop.prevent='startRoundC')
          b-form-group(
            :state='noteState',
            label='Note',
            label-for='note-input',
            invalid-feedback='Note is required'
          )
            b-form-textarea#note-input(
              v-model='note',
              rows='3',
              max-rows='6',
              :state='noteState',
              required
            )
      b-modal#close-pending(
        title='Close pending contest',
        @show='resetModal',
        @hidden='resetModal',
        @ok='closePending'
      )
        form(ref='closePendingForm', @submit.stop.prevent='closePending')
          b-form-group(
            :state='reasonState',
            label='Why are you closing this contest?',
            label-for='reason-input',
            invalid-feedback='Reason is required'
          )
            b-form-textarea#reason-input(
              v-model='reason',
              rows='3',
              max-rows='6',
              :state='reasonState',
              required
            )
      b-modal#force-close(
        title='Complete contest without winner',
        @show='resetModal',
        @hidden='resetModal',
        @ok='forceClose'
      )
        form(ref='forceCloseForm', @submit.stop.prevent='forceClose')
          b-form-group(
            :state='reasonState',
            label='Why do you want to complete this contest without winner?',
            label-for='reason-input',
            invalid-feedback='Reason is required'
          )
            b-form-textarea#reason-input(
              v-model='reason',
              rows='3',
              max-rows='6',
              :state='reasonState',
              required
            )
      b-card(v-if='contest')
        contest-form(:model='contest', :entries='entries', ref='form')

        template(v-slot:header)
          //- b-button(variant="outline-primary", @click="exportCSV")
          //-   i.fa.fa-file-excel-o.mr-2
          //-   span Download Logs
          b-button.mr-2(
            variant='outline-primary',
            v-b-modal.start-round-c,
            v-if='contest.status === "ended"'
          )
            span Start Round C
          b-button(
            variant='outline-primary',
            v-b-modal.close-pending,
            v-if='contest.status === "pending"'
          )
            span Close contest
          b-button(
            variant='outline-primary',
            v-b-modal.force-close,
            v-if='contest.status !== "pending" && contest.status !== "completed"  && contest.status !== "closed"'
          )
            span Complete contest without winner
          b-button(
            variant='outline-info',
            @click='reopenComplete',
            v-if='contest.status === "completed"'
          )
            span Re-open
          b-button(
            variant='outline-info',
            @click='reopenClosed',
            v-if='contest.status === "closed"'
          )
            span Re-open
          button.btn.btn-info(
            :disabled='isLoading',
            @click='save',
            style='float: right'
          ) Save
</template>

<script>
import ContestForm from './ContestForm';

const dt0 = new Date(0);

export default {
  name: 'ContestView',
  components: { ContestForm },

  data: () => ({
    isLoading: false,

    contests: {
      NameContest: 'Name Contest',
      TaglineContest: 'Tagline Contest',
      LogoContest: 'Logo Contest',
    },

    contest: {
      customer: {
        billingInfo: {},
      },

      createdAt: dt0,
      paidAt: dt0,
      liveAt: dt0,
      completedAt: dt0,

      information: {
        nameTypePreferences: [''],
        nameStylePreferences: [''],
      },
    },

    note: '',
    noteState: null,
    reason: '',
    reasonState: null,

    entries: [],
  }),

  computed: {
    contestId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.$on('refresh', this.refresh.bind(this));
    this.$store.dispatch('SET_LOADER_STATE', false);
    this.refresh();
  },

  methods: {
    contestStatusClass(status) {
      switch (status) {
        case 'pending':
        case 'closed':
          return 'warning';
        case 'live':
          return 'primary';
        case 'completed':
          return 'success';
        default:
          return 'light';
      }
    },
    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      const fd = this.$helpers.objectToFormData(model);

      this.isLoading = true;

      this.$http
        .put(`v1/admin/contests/${this.contestId}`, fd)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'Contest succesfully updated.');
          this.refresh();
        })
        .catch(error => {
          this.$toast.error('Contest save error', error.message);
          this.isLoading = false;
        });
    },
    async reopenComplete() {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to reopen this contest?`, {
          centered: true,
        })
        .then(async res => {
          if (!res) return;
          this.isLoading = true;

          this.$http
            .post(`v1/admin/contests/${this.contestId}/reopen`)
            .then(result => {
              this.isLoading = false;
              this.$toast.success('Complete', 'Contest succesfully reopened.');
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Contest save error', error.message);
              this.isLoading = false;
            });
        });
    },
    async reopenClosed() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to reopen this closed contest? The contest will be reopened with "PENDING" status`,
          {
            centered: true,
          },
        )
        .then(async res => {
          if (!res) return;
          this.isLoading = true;

          this.$http
            .post(`v1/admin/contests/${this.contestId}/reopen`)
            .then(result => {
              this.isLoading = false;
              this.$toast.success('Complete', 'Contest succesfully reopened.');
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Contest save error', error.message);
              this.isLoading = false;
            });
        });
    },
    resetModal() {
      this.note = '';
      this.noteState = null;
      this.reason = '';
      this.reasonState = null;
    },
    async closePending(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault();
      }

      const valid = this.$refs.closePendingForm.checkValidity();
      this.reasonState = valid;

      if (!valid) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post(`v1/admin/contests/${this.contestId}/close`, {
          reason: this.reason,
        })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('close-pending');
          });
          this.isLoading = false;
          this.$toast.success('Complete', 'Contest closed successfully');
          this.refresh();
        })
        .catch(error => {
          this.$toast.error('Contest save error', error.message);
          this.isLoading = false;
        });
    },
    async forceClose(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault();
      }

      const valid = this.$refs.forceCloseForm.checkValidity();
      this.reasonState = valid;

      if (!valid) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post(`v1/admin/contests/${this.contestId}/close?force=true`, {
          reason: this.reason,
        })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('force-close');
          });
          this.isLoading = false;
          this.$toast.success('Complete', 'Contest closed successfully');
          this.refresh();
        })
        .catch(error => {
          this.$toast.error('Contest save error', error.message);
          this.isLoading = false;
        });
    },
    async startRoundC(bvModalEvt) {
      if (bvModalEvt) {
        bvModalEvt.preventDefault();
      }

      const valid = this.$refs.startRoundCForm.checkValidity();
      this.noteState = valid;

      if (!valid) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post(`v1/admin/contests/${this.contestId}/round_c`, {
          note: this.note,
        })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide('start-round-c');
          });
          this.isLoading = false;
          this.$toast.success('Complete', 'Round C succesfully started.');
          this.refresh();
        })
        .catch(error => {
          this.$toast.error('Contest save error', error.message);
          this.isLoading = false;
        });
    },

    async refresh() {
      try {
        this.isLoading = true;
        this.contest = await this.$http.get(
          'v1/admin/contests/' + this.contestId,
        );
        this.entries = await this.$http.get(
          `v1/admin/contests/${this.contestId}/entries`,
        );
        this.isLoading = false;
      } catch (error) {
        this.$toast.error('Contest/Entries fetching error', error.message);
        this.isLoading = false;
      }
    },
  },
};
</script>
