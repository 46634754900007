import _debounce from 'lodash/debounce';

const LOADER_DEBOUNCE = _debounce(function (commit, value) {
  commit('SET_LOADER_STATE', value);
}, 300);

export function SET_LOADER_STATE({ commit, state }, value) {
  if (value && state.loader) return;

  if (value) {
    commit('SET_LOADER_STATE', true);
  } else {
    LOADER_DEBOUNCE(commit, false);
  }
}
