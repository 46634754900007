const page = r => require.ensure([], () => r(require('./list.vue')), 'payouts');

export const routes = [
  {
    path: '/payouts',
    name: 'payouts',
    component: page,
    meta: {
      title: 'Payouts',
      view: 'panel',
    },
  },
];
