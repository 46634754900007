<template lang="pug">
	#industry_view
		.row
			.col-md
				.row
					.col-12
						.card
							slide-loader(:display="isLoading")
							.card-body(v-show="industry")
								industry-form(:model="industry", ref="form")
							.card-body(v-show="!industry")
								.text-center.mt-2.mb-2 Loading...
							.card-footer.text-right(v-if="industry")
								// button.btn.btn-danger.mr-2(:disabled="isLoading", @click="remove") Remove
								router-link(:to="{name: 'industries'}") Cancel
								button.btn.btn-info.ml-2(:disabled="isLoading", @click="save") Save
</template>

<script>
import IndustryForm from './IndustryForm';

export default {
  name: 'IndustryView',
  components: { IndustryForm },
  data: () => ({
    isLoading: false,
    industry: {},
  }),
  computed: {
    industryId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.$on('refresh', this.refresh.bind(this));
    this.$store.dispatch('SET_LOADER_STATE', false);
    this.refresh();
  },
  methods: {
    remove() {
      this.$bvModal
        .msgBoxConfirm(`Delete this industry`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete('v1/admin/industries/' + this.industry._id)
            .then(() => {
              this.$toast.success(
                'Complete',
                `${this.industry.name} successfully removed.`,
              );
              this.$router.push({ name: 'industries' });
            })
            .catch(error => {
              this.$toast.error('Industry delete error', error.message);
              this.isLoading = false;
            });
        });
    },

    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .put(`v1/admin/industries/${this.industryId}`, model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'Industry succesful updated.');
          this.$router.push({
            name: 'industries',
          });
        })
        .catch(error => {
          this.$toast.error('Industry save error', error.message);
          this.isLoading = false;
        });
    },

    refresh() {
      this.isLoading = true;

      if (this.industryId === 'import') {
        this.$http.get('v1/admin/industries/import').then(result => {
          document.location = '/industries';
        });
      } else {
        this.$http
          .get('v1/admin/industries/' + this.industryId)
          .then(result => {
            this.industry = result;
            this.isLoading = false;
          })
          .catch(error => {
            this.$toast.error('Industry fetching error', error.message);
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
