<template lang="pug">
  b-container#entry_view(fluid)
    slide-loader(:display="isLoading")
    b-row(v-if="entry")
      b-col
        h4
          router-link(:to="{name: 'contests.view', params: { id: entry.contest._id }}") \#{{ entry.contest.number }}
          i.fa.fa-angle-double-right.mr-2.ml-2
          span {{ entries[entry.type] }}
        .form-group
          span.badge.mr-2(:class="`badge-${entryStatusClass(entry.status)}`") {{ entry.status }}
            br
          p(v-if="entry.status === 'rejected'") Rejected
            span(v-if="entry.rejectedAt")  at {{ entry.rejectedAt | moment("YYYY-MM-DD HH:mm:ss") }}
            span  with reason:
              i  {{ entry.rejectReason }}
    b-row(v-if="entry")
      b-col
        b-card
          entry-form(:model="entry", ref="form")

          template(v-slot:header)
            button.btn.btn-info(:disabled="isLoading", @click="save") Save
</template>

<script>
import EntryForm from './EntryForm';
import EntryStatusClass from '../mixins/entry-status-class';

export default {
  name: 'EntryView',
  components: { EntryForm },
  mixins: [EntryStatusClass],

  data: () => ({
    isLoading: false,

    entry: null,

    entries: {
      NameEntry: 'Name Entry',
      TaglineEntry: 'Tagline Entry',
      LogoEntry: 'Logo Entry',
    },
  }),
  computed: {
    entryId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.$on('refresh', this.refresh.bind(this));
    this.$store.dispatch('SET_LOADER_STATE', false);
    this.refresh();
  },

  methods: {
    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .put(`v1/admin/entries/${this.entryId}`, model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'Entry was succesfully updated.');
          this.$router.push({
            name: 'entries',
          });
        })
        .catch(error => {
          this.$toast.error('Entry save error', error.message);
          this.isLoading = false;
        });
    },

    async refresh() {
      this.isLoading = true;

      try {
        this.entry = await this.$http.get('v1/admin/entries/' + this.entryId);
      } catch (error) {
        this.$toast.error('Entry fetching error', error.message);
      }

      this.isLoading = false;
    },
  },
};
</script>
