export default {
  install: Vue => {
    const $vue = new Vue();
    Vue.$toast = {
      error: (title, text) => {
        if (!text) {
          text = title;
          title = undefined;
        }
        $vue.$bvToast.toast(text, {
          title: title || 'Error',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
        });
      },
      success: (title, text) => {
        if (!text) {
          text = title;
          title = undefined;
        }
        $vue.$bvToast.toast(text, {
          title: title || 'Success',
          variant: 'success',
          toaster: 'b-toaster-top-center',
        });
      },
    };

    Vue.prototype.$toast = Vue.$toast;
  },
};
