import { USER_ROLES } from '../shared';

export default {
  token: null,
  apikey: null,
  account: {
    username: '...',
    roles: [USER_ROLES.ROLE_ANON],
  },
  auth: false,
};
