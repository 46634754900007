<template lang="pug">
  #panel(:class="panelClass")
    app-header

    b-container(fluid)
      b-row
        b-col(cols="12", md="3", xl="2", class="pt-4 pb-2")
          app-sidebar
        b-col(cols="12", md="9", xl="10", class="pt-4 pb-1")
          router-view
</template>

<script>
import '../scss/panel.scss';

import store from '../store';

import AppHeader from '../components/AppHeader.vue';
import AppSidebar from '../components/AppSidebar.vue';

export default {
  name: 'PanelContainer',
  components: {
    AppHeader,
    AppSidebar,
  },

  beforeRouteEnter(to, from, next) {
    if (!store.state.auth) {
      console.warn('redirect to login ->', 'auth state = false');
      return next({ name: 'login' });
    }

    return next();
  },

  data: () => ({
    isSidebarShow: true,
  }),

  computed: {
    name() {
      return this.$route.name;
    },

    list() {
      return this.$route.matched;
    },

    panelClass() {
      return {
        sidebar__show: this.isSidebarShow,
      };
    },

    isLoading() {
      return this.$store.state.loader;
    },
  },

  watch: {
    isLoading(newValue) {
      if (newValue && window.innerWidth < 768) {
        this.isSidebarShow = false;
      }
    },
  },

  created() {
    // this.isSidebarShow = window.innerWidth >= 992;
    this.$on('sidebar:toggle', this.toggleSidebar);

    const token = this.$store.state.token;
    document.cookie = `sid=${token}`;
  },

  methods: {
    toggleSidebar() {
      this.isSidebarShow = !this.isSidebarShow;
    },
  },
};
</script>
