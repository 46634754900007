























































export default {
  name: 'ContestsPage',

  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('SET_LOADER_STATE', false);
    next();
  },

  data: () => ({
    list: [],
    displayList: [],
    searchText: '',
    isLoading: false,

    perPage: 50,
    currentPage: 1,
    sortBy: 'createdAt',
    sortDesc: true,
    numberOfPages: 1,
    totalResults: 0,
    advanced: false,

    fields: [
      {
        key: 'number',
        label: '#',
        sortable: true,
      },
      {
        key: 'customer',
        sortable: true,
      },
      { key: 'industry' },
      {
        key: 'type',
        sortable: true,
        formatter: value => value.replace('Contest', '').toLowerCase(),
      },
      {
        key: 'prizeAmount',
        label: 'Prize',
        formatter: value => `$${value / 100}`,
      },
      { key: 'entriesAmount', label: 'Entries', sortable: true },
      { key: 'status' },
    ],
  }),
  url: {
    searchText: 'search',
  },

  computed: {
    showList() {
      return this.$route.name === 'contests';
    },
  },

  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
    sortBy() {
      this.refresh();
    },
    sortDesc() {
      this.refresh();
    },
  },

  mounted() {
    if (typeof this.$route.query.advanced !== 'undefined') {
      this.advanced = true;
    }
    if (this.$route.query.page) {
      this.currentPage = Number.parseInt(this.$route.query.page, 10) || 1;
    }
    if (this.showList) {
      this.refresh();
    }
    this.$store.dispatch('SET_LOADER_STATE', false);
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    contestStatusClass(status) {
      switch (status) {
        case 'pending':
          return 'warning';
        case 'live':
          return 'primary';
        case 'completed':
          return 'success';
        default:
          return 'light';
      }
    },

    removeContest(contest) {
      this.$bvModal
        .msgBoxConfirm(
          `Contest #${contest.number} will be permanently deleted`,
          { centered: true },
        )
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/contests/${contest._id}`)
            .then(() => {
              this.$toast.success(
                `Contest #${contest.number} successfully removed.`,
              );
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Contest delete error', error.message);
              this.isLoading = false;
            });
        });
    },

    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/contests', {
          params: {
            search: this.searchText,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc,
            page: this.currentPage,
            limit: this.perPage,
          },
        })
        .then(result => {
          this.list = result.items;
          this.numberOfPages = Math.floor(result.count / this.perPage + 1);
          this.isLoading = false;
          this.totalResults = result.count;
        })
        .catch(error => {
          this.$toast.error('Contests fetching error', error.message);
          this.isLoading = false;
        });
    },
    exportCSV() {
      return this.downloadFile('v1/admin/contests?format=csv', 'contests.csv', {
        params: {
          search: this.searchText,
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
        },
      });
    },
  },
};
