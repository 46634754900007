<template lang="pug">
  b-navbar(type="dark", variant="dark")
    b-navbar-brand(href="/dashboard")
      img.logo(src="/static/img/logo.png")

    b-navbar-nav(class="ml-auto")
      b-nav-item-dropdown(:text="user.fullName" right)
        b-dropdown-item(@click="logout") Log out
</template>

<script>
export default {
  name: 'AppHeader',
  computed: {
    user() {
      // console.log('account', this.$store.state.account);
      return this.$store.state.account;
    },
  },
  methods: {
    logout() {
      this.$store.commit('LOGOUT');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped lang="scss">
.profile {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;

  p {
    margin: 0;
  }
}

.avatar {
  display: inline;
}

.logo {
  height: 43px;
  margin-top: 8px;
}

.avatar > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
