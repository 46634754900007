import Router from 'vue-router';
import CommonContainer from './containers/Common.vue';
import PanelContainer from './containers/Panel.vue';
import store from './store';
import Page404 from './views/Page404.vue';

const PANEL_ROUTES: any[] = [];
const COMMON_ROUTES: any[] = [];

// Initialize modules
[
  '_auth',
  '_payouts',
  '_contests',
  '_dashboard',
  '_discounts',
  '_entries',
  '_industries',
  '_logs',
  '_tlds',
  '_users',
].forEach(initModule);

// Register 404
COMMON_ROUTES.push({ path: '*', component: Page404 });

// Initialize router
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/',
      component: PanelContainer,
      children: PANEL_ROUTES,
      meta: { title: 'Panel' },
      beforeEnter: checkAuthState,
    },
    {
      path: '/',
      component: CommonContainer,
      children: COMMON_ROUTES,
    },
  ],
});

// Drop loader state
router.beforeEach((to, from, next) => {
  store.commit('SET_LOADER_STATE', true);
  next();
});

console.log('app:router', 'initialized', router.getRoutes());

export default router;

function initModule(moduleName: string) {
  const mdl = require(`./${moduleName}`);

  if (!mdl.routes || mdl.routes.length === 0) {
    console.warn('app:module', moduleName, 'has no routes.');
    return;
  }

  if (moduleName === '_auth' || moduleName === '_service') {
    COMMON_ROUTES.push.apply(COMMON_ROUTES, mdl.routes);
  } else {
    PANEL_ROUTES.push.apply(PANEL_ROUTES, mdl.routes);
  }
}

function checkAuthState(_to: any, _from: any, next: any) {
  if (!store.state.auth) {
    console.warn('redirect to login ->', 'auth state = false');
    return next({ name: 'login' });
  }

  return next();
}
