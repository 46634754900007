<template lang="pug">
	#tld_view
		.row
			.col-md
				.row
					.col-12
						.card
							slide-loader(:display="isLoading")
							.card-body(v-show="tld")
								tld-form(:model="tld", ref="form")
							.card-body(v-show="!tld")
								.text-center.mt-2.mb-2 Loading...
							.card-footer.text-right(v-if="tld")
								// button.btn.btn-danger.mr-2(:disabled="isLoading", @click="remove") Remove
								router-link(:to="{name: 'tlds'}") Cancel
								button.btn.btn-info.ml-2(:disabled="isLoading", @click="save") Save
</template>

<script>
import TLDform from './TLDform';

export default {
  name: 'TLDview',
  components: { 'tld-form': TLDform },
  data: () => ({
    isLoading: false,
    tld: {},
  }),
  computed: {
    tldId() {
      return this.$route.params.id;
    },
  },

  mounted() {
    this.$on('refresh', this.refresh.bind(this));
    this.$store.dispatch('SET_LOADER_STATE', false);
    this.refresh();
  },
  methods: {
    remove() {
      this.$bvModal
        .msgBoxConfirm(`Delete this TLD`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete('v1/admin/tlds/' + this.tld._id)
            .then(() => {
              this.$toast.success(
                'Complete',
                `.${this.tld.name} successfully removed.`,
              );
              this.$router.push({ name: 'tlds' });
            })
            .catch(error => {
              this.$toast.error('TLD delete error', error.message);
              this.isLoading = false;
            });
        });
    },

    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .put(`v1/admin/tlds/${this.tldId}`, model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'TLD succesful updated.');
          this.$router.push({
            name: 'tlds',
          });
        })
        .catch(error => {
          this.$toast.error('TLD save error', error.message);
          this.isLoading = false;
        });
    },

    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/tlds/' + this.tldId)
        .then(result => {
          this.tld = result;
          this.tld.tldPrice = this.tld.price / 100;
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('TLD fetching error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
