<template lang="pug">
	.row
		.col-md
			.card
				slide-loader(:display="isLoading")
				.card-header
					span New Discount Code
				.card-body
					item-form(:model="item", ref="form")

				.card-footer.text-right
					router-link(:to="{name: 'discounts'}") Cancel
					button.btn.btn-success.ml-2(:disabled="isLoading", @click="save") Save
</template>

<script>
import form from './form.vue';

export default {
  name: 'NewDiscountCode',
  components: { 'item-form': form },

  data: () => ({
    item: {
      code: '',
      isPercent: false,
      usageLimit: 1,
      amount: 0,
    },
    isLoading: false,
  }),

  mounted() {
    this.$store.dispatch('SET_LOADER_STATE', false);
  },

  methods: {
    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post('v1/admin/discounts', model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'New item successfully created.');
          this.$router.push({
            name: 'discounts',
          });
        })
        .catch(error => {
          this.$toast.error('Item save error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
