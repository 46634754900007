








































































































import Vue from 'vue';
import { userIsWriter, userIsDesigner, CREATIVE_TYPE } from '../shared';

export default Vue.extend({
  name: 'UsersPage',
  data: () => ({
    list: [],
    displayList: [],
    searchText: '',
    filterBy: {
      tiers: null,
    },
    isLoading: false,

    perPage: 50,
    currentPage: 1,
    sortBy: 'createdAt',
    sortDesc: true,
    numberOfPages: 1,
    totalResults: 0,

    fields: [
      {
        key: 'fullName',
        label: 'Full Name',
        sortable: true,
      },
      {
        key: 'email',
        sortable: true,
      },
      // {
      //   key: "roles",
      //   sortable: true,
      //   sortByFormatted: true,
      //   formatter: (roles, key, item) => {
      //     if (roles) {
      //       return roles.map(v => v.replace("ROLE_", "").toLowerCase()).join(", ");
      //     }
      //   }
      // },
      {
        key: 'tiers',
        label: 'Type / Tier',
        formatter: (tiers, key, user) => {
          const str: string[] = [];

          if (userIsWriter(user)) {
            str.push(
              `${CREATIVE_TYPE.WRITER} (tier ${
                user.tiers?.[CREATIVE_TYPE.WRITER]
              })`,
            );
          }

          if (userIsDesigner(user)) {
            str.push(
              `${CREATIVE_TYPE.DESIGNER} (tier ${
                user.tiers?.[CREATIVE_TYPE.DESIGNER]
              })`,
            );
          }

          return str.join(', ');
        },
      },
      {
        key: 'contestsWonCounter',
        label: 'Contests Won',
        formatter: (val, key, user) => {
          return typeof val !== 'undefined' ? Number.parseInt(val) : 'N/A';
        },
        sortable: true,
      },
      {
        key: 'entriesCounter',
        label: 'Entries',
        formatter: (val, key, user) => {
          return Number.parseInt(val);
        },
        sortable: true,
      },
      {
        key: 'karmaPoints',
        label: 'Karma',
        formatter: (v, key, user) => {
          if (!v) {
            return 0;
          }
          return Number.parseInt(v);
        },
        sortable: true,
      },
      {
        key: 'accuracyScore',
        label: 'Accuracy',
        formatter: (v, key, user) => {
          return Math.round(v) + '%';
        },
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        formatter: (_1, _2, user) => {
          return user.disabledAt
            ? 'closed'
            : user.suspendedAt
            ? 'suspended'
            : 'active';
        },
        sortable: false,
      },
      {
        key: 'createdAt',
        label: 'Created At',
        formatter: (createdAt, key, user) => {
          return createdAt.slice(0, 10);
        },
        sortable: true,
      },
      { key: 'actions', thStyle: 'width: 150px', tdClass: 'tb-column' },
    ],
  }),
  url: {
    searchText: 'search',
  },
  computed: {
    showList() {
      return this.$route.name === 'users';
    },
  },
  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
    sortBy() {
      this.refresh();
    },
    sortDesc() {
      this.refresh();
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.currentPage = Number.parseInt(this.$route.query.page, 10) || 1;
    }
    if (this.showList) this.refresh();
    this.$store.dispatch('SET_LOADER_STATE', false);
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    filter() {
      const context = this;

      Vue.nextTick(() => {
        context.refresh();
      });
    },

    newUser() {
      this.$router.push({ name: 'users.new' });
    },

    impersonateUserUrl(user) {
      return this.$clientAppUrl + 'login?as_user=' + user.apikey;
    },

    suspendAccount(user) {
      this.$bvModal
        .msgBoxConfirm(`Suspend this account`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/users/${user._id}?action=suspend`)
            .then(() => {
              this.$toast.success('Complete', `Account suspended.`);
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.isLoading = false;
            });
        });
    },

    closeAccount(user) {
      this.$bvModal
        .msgBoxConfirm(`Close this account`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/users/${user._id}?action=disable`)
            .then(() => {
              this.$toast.success('Complete', `Account closed.`);
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.isLoading = false;
            });
        });
    },

    refresh() {
      this.isLoading = true;

      const filter = { tiers: {} };
      if (this.filterBy.tiers) {
        filter.tiers[this.filterBy.tiers.split(':')[0]] = Number(
          this.filterBy.tiers.split(':')[1],
        );
      }

      this.$http
        .get('v1/admin/users', {
          params: {
            search: this.searchText,
            filter_by: filter,
            sort_by: this.sortBy,
            sort_desc: this.sortDesc,
            page: this.currentPage,
            limit: this.perPage,
          },
        })
        .then(result => {
          this.list = result.items;
          this.isLoading = false;
          this.numberOfPages = Math.floor(result.count / this.perPage + 1);
          this.totalResults = result.count;
        })
        .catch(error => {
          this.$toast.error('Users fetching error', error.message);
          this.isLoading = false;
        });
    },
    exportCSV() {
      const filter = { tiers: {} };
      if (this.filterBy.tiers) {
        filter.tiers[this.filterBy.tiers.split(':')[0]] = Number(
          this.filterBy.tiers.split(':')[1],
        );
      }

      return this.downloadFile('v1/admin/users?format=csv', 'users.csv', {
        params: {
          search: this.searchText,
          filter_by: filter,
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
        },
      });
    },
  },
} as any);
