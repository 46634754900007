<template lang="pug">
	.row
		.col-md
			.card
				slide-loader(:display="isLoading")
				.card-header
					span New Domain Extension
				.card-body
					tld-form(:model="TLD", ref="form")

				.card-footer.text-right
					router-link(:to="{name: 'tlds'}") Cancel
					button.btn.btn-success.ml-2(:disabled="isLoading", @click="save") Save
</template>

<script>
import TLDform from './TLDform.vue';

export default {
  name: 'TLDnew',
  components: { 'tld-form': TLDform },

  data: () => ({
    TLD: {
      name: '',
      price: '',
      popular: true,
    },
    isLoading: false,
  }),

  mounted() {
    this.$store.dispatch('SET_LOADER_STATE', false);
  },

  methods: {
    async save() {
      const model = await this.$refs.form.validate();

      if (!model) {
        return;
      }

      this.isLoading = true;

      this.$http
        .post('v1/admin/tlds', model)
        .then(result => {
          this.isLoading = false;
          this.$toast.success('Complete', 'New TLD successful created.');
          this.$router.push({
            name: 'tlds',
          });
        })
        .catch(error => {
          this.$toast.error('TLD save error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
