import axios, { AxiosResponse } from 'axios';

import router from '../router';
import store from '../store';

function logout() {
  if (store.state.auth) {
    store.commit('LOGOUT');
    router.push({ name: 'login' });
  }
}

function definePayload(obj: any, payload: any) {
  const safePayload = Object.assign({}, payload);
  delete safePayload.data;

  Object.defineProperty(obj, '_payload', {
    enumerable: false,
    writable: false,
    value: safePayload,
  });

  return obj;
}

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

http.interceptors.request.use(
  function (config) {
    const token = store.state.token;

    if (typeof token === 'string') {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    // Do something with request error
    return Promise.reject(err);
  },
);

export default {
  install: Vue => {
    http.interceptors.response.use(
      function (response: AxiosResponse<any>) {
        const result = response.data;

        if (result instanceof Blob) {
          return result;
        }

        if (typeof result.data === 'object') {
          definePayload(result.data, result);
        }

        return result.data;
      },
      function (error) {
        if (!error.response) {
          Vue.$toast.error('Network error', 'Connection to API refused');
          return;
        }

        const result = error.response.data;
        let handled = true;
        switch (error.response.status) {
          case 402:
            logout();
            break;

          case 401:
            logout();
            Vue.$toast.error('Unauthorized', 'Unauthorized request.');
            break;

          case 405:
            logout();
            Vue.$toast.error('Session expired', 'Access token has expired.');
            break;

          case 400:
            if (error.respons && error.response.data) {
              Vue.$toast.error('Invalid data', error.response.data.message);
            }
            break;

          default:
            handled = false;
            break;
        }

        const err: any = new Error(result.message);

        err.handled = handled;
        err.rawMsg = result.message;
        err.messages = result.messages;
        err.code = result.code;
        err.statusCode = result.status || 500;

        definePayload(err, result);

        return Promise.reject(err);
      },
    );

    Vue.axios = http;
    Vue.$http = http;

    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return http;
        },
      },

      $http: {
        get() {
          return http;
        },
      },

      downloadFile: {
        get() {
          return async (url: string, name: string, options: any = {}) => {
            const response: any = await http.get(
              url,
              Object.assign(options, {
                responseType: 'blob',
              }),
            );

            const downloadUrl = window.URL.createObjectURL(
              new Blob([response]),
            );

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', name);
            document.body.append(link);
            link.click();
            link.remove();
          };
        },
      },
    });
  },
};
