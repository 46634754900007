<template>
  <div class="loader">
    <transition
      name="slide"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
    >
      <div v-if="state" :class="loaderClass" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SlideLoader',
  props: ['display'],
  data: () => ({
    state: false,
    animEnd: false,
  }),
  computed: {
    loaderClass() {
      return 'loader__progress' + (this.animEnd ? ' slide-wait' : '');
    },
  },
  watch: {
    display(newValue) {
      if (newValue) {
        this.state = true;
      } else {
        setTimeout(() => (this.state = false), 300);
      }
    },
  },
  mounted() {
    this.state = !!this.display;
  },
  methods: {
    afterEnter() {
      this.animEnd = true;
    },
    beforeLeave() {
      this.animEnd = false;
    },
  },
};
</script>

<style lang="scss">
.loader {
  min-width: 100%;
  height: 1px;
  margin-top: -1px;
  overflow-x: hidden;
  display: flex;
}

.loader__progress {
  min-height: 100%;
  min-width: 100%;
  background-color: #496fa9;
}
</style>
