const page = r => require.ensure([], () => r(require('./list.vue')), 'discounts')
const view = r => require.ensure([], () => r(require('./single.vue')), 'discounts')
const newItem = r => require.ensure([], () => r(require('./new.vue')), 'discounts')

export const routes = [{
  path: '/discounts',
  name: 'discounts',
  component: page,
  meta: {
    title: 'Discounts',
    view: 'panel'
  },
  children: [{
    path: 'new',
    name: 'discounts.new',
    component: newItem,
    meta: {
      title: 'New',
      view: 'panel'
    }
  }, {
    path: ':id',
    name: 'discounts.view',
    component: view,
    meta: {
      title: 'View',
      view: 'panel'
    }
  }]
}]
