// const LoginPage = () => import(/* webpackChunkName: "LoginPage" */ './LoginPage.vue')
import LoginPage from './LoginPage.vue'

export const routes = [{
  path: '/login',
  name: 'login',
  component: LoginPage,
  meta: {
    title: 'Auth',
    appClass: 'common'
  }
}]
