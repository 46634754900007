




























































































































































































































































































































































































































import _pick from 'lodash/pick';
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import { USER_ROLES } from '../shared';
import EntryStatusClass from '../mixins/entry-status-class';

const MODEL_FIELDS = [
  'fullName',
  'userName',
  'email',
  'password',
  'roles',
  'billingInfo',
  'tiers',
];

export default Vue.extend({
  name: 'UserForm',
  components: { Multiselect },
  mixins: [EntryStatusClass],
  props: ['model'],
  data() {
    return {
      advanced: false,
      roles: Object.values(USER_ROLES),
      selectedEntries: [],

      entriesTable: {
        currentPage: 1,
        filter: null,
        filterOn: [],
        tableBusy: false,
        fields: [
          {
            key: 'contest',
            label: 'Contest',
          },
          {
            key: 'type',
            sortable: true,
            formatter: value => value.replace('Entry', '').toLowerCase(),
          },
          { key: 'name', sortable: true },
          { key: 'domain', sortable: true },
          { key: 'tagline', sortable: true },
          { key: 'file', label: 'Attachment' },
          {
            key: 'createdAt',
            label: 'Created At',
          },
          'actions',
        ],
      },
      scoringTable: {
        currentPage: 1,
        filter: null,
        filterOn: [],
        tableBusy: false,
        fields: [
          {
            key: 'contest',
            label: 'Contest',
            sortable: true,
          },
          {
            key: 'score',
            formatter: value => (value === 1 ? '+1' : '-1'),
          },
          {
            key: 'createdAt',
            label: 'Added at',
          },
          'actions',
        ],
      },
      contestsTable: {
        currentPage: 1,
        filter: null,
        filterOn: [],
        tableBusy: false,
        fields: [
          {
            key: 'number',
            label: '#',
            sortable: true,
          },
          { key: 'industry' },
          { key: 'order' },
          {
            key: 'type',
            sortable: true,
            formatter: value => value.replace('Contest', '').toLowerCase(),
          },
          { key: 'status' },
          'actions',
        ],
      },
    };
  },

  computed: {
    pendingEntriesNr() {
      return this.$props.model.pendingEntries.length;
    },
  },
  mounted() {
    console.log(this.$props.model);
    if (typeof this.$route.query.advanced !== 'undefined') {
      this.advanced = true;
    }
  },
  methods: {
    contestStatusClass(status) {
      switch (status) {
        case 'pending':
          return 'warning';
        case 'live':
          return 'primary';
        case 'completed':
          return 'success';
        default:
          return 'light';
      }
    },
    validate() {
      return this.$validator
        .validateAll()
        .then(() => _pick(this.model, MODEL_FIELDS))
        .then(model => {
          if (!model.password || model.password.length === 0) {
            delete model.password;
          }
          return model;
        })
        .catch(() => false);
    },

    selectAllRows() {
      this.$refs.entriesTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.entriesTable.clearSelected();
    },

    markOrderAsPaid(orderId) {
      this.$bvModal
        .msgBoxConfirm(`Order #${orderId} will be marked as paid`, {
          centered: true,
        })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .post(`v1/admin/bundles/${orderId}/confirmAsPaid`)
            .then(() => {
              this.$toast.success(
                `Order #${orderId} successfully marked as paid.`,
              );
              this.$emit('dataChanged');
            })
            .catch(error => {
              this.$toast.error('Order save error', error.message);
              this.$emit('dataChanged');
              this.isLoading = false;
            });
        });
    },

    markOrderAsCancelled(orderId) {
      this.$bvModal
        .msgBoxConfirm(`Order #${orderId} will be marked as cancelled`, {
          centered: true,
        })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/bundles/${orderId}`)
            .then(() => {
              this.$toast.success(
                `Order #${orderId} successfully marked as cancelled.`,
              );
              this.$emit('dataChanged');
            })
            .catch(error => {
              this.$toast.error('Order save error', error.message);
              this.$emit('dataChanged');
              this.isLoading = false;
            });
        });
    },
    clearBalance() {
      this.$bvModal
        .msgBoxConfirm(`The balance will be cleared`, {
          centered: true,
        })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/users/${this.model._id}/balance`)
            .then(() => {
              this.$toast.success(`The balance has been cleared.`);
              this.$emit('dataChanged');
            })
            .catch(error => {
              this.$toast.error('Error', error.message);
              this.$emit('dataChanged');
              this.isLoading = false;
            });
        });
    },
    publishSelected() {
      this.$bvModal
        .msgBoxConfirm(`Publish selected entries`, { centered: true })
        .then(async res => {
          if (!res) return;

          this.entriesTable.tableBusy = true;
          const updates = this.selectedEntries.map(entry =>
            this.$http
              .put(`v1/admin/entries/${entry._id}`, { status: 'active' })
              .catch(error => {
                console.error(entry._id, error);
              }),
          );

          await Promise.all(updates);

          this.entriesTable.tableBusy = false;
          this.$emit('dataChanged');
        });
    },
    publishEntry(entry, event) {
      if (event) {
        event.preventDefault();
      }
      this.$bvModal
        .msgBoxConfirm(`Publish this entry`, { centered: true })
        .then(async res => {
          if (!res) return;

          this.entriesTable.tableBusy = true;
          await this.$http
            .put(`v1/admin/entries/${entry._id}`, { status: 'active' })
            .then(() => {
              this.$toast.success(
                'Complete',
                `Entry ${entry._id} was successfully published.`,
              );
            })
            .catch(error => {
              this.$toast.error('Entry publish error', error.message);
            });

          this.$emit('dataChanged');
          this.entriesTable.tableBusy = false;
        });
    },

    declineEntry(entry, event) {
      if (event) {
        event.preventDefault();
      }
      this.$bvModal
        .msgBoxConfirm(`Decline this entry`, { centered: true })
        .then(async res => {
          if (!res) return;

          this.entriesTable.tableBusy = true;
          await this.$http
            .put(`v1/admin/entries/${entry._id}`, { status: 'declined' })
            .then(() => {
              this.$toast.success(
                'Complete',
                `Entry ${entry._id} was successfully declined.`,
              );
            })
            .catch(error => {
              this.$toast.error('Entry declining error', error.message);
            });

          this.$emit('dataChanged');
          this.entriesTable.tableBusy = false;
        });
    },
    declineSelected() {
      this.$bvModal
        .msgBoxConfirm(`Decline selected entries`, { centered: true })
        .then(async res => {
          if (!res) return;

          this.entriesTable.tableBusy = true;
          const updates = this.selectedEntries.map(entry =>
            this.$http
              .put(`v1/admin/entries/${entry._id}`, { status: 'declined' })
              .catch(error => {
                console.error(entry._id, error);
              }),
          );

          await Promise.all(updates);

          this.entriesTable.tableBusy = false;
          this.$emit('dataChanged');
        });
    },
    onRowSelected(items) {
      this.selectedEntries = items;
    },
  },
});
