<template lang="pug">
	.block.p-4
		.content
			h1.title 404
			p Page not found
</template>

<script>
export default {
  name: 'Page404',
};
</script>
