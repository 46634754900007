export default {
  methods: {
    entryStatusClass(status) {
      switch (status) {
        case 'active': return 'primary'
        case 'frontrunner': return 'info'
        case 'winner': return 'success'
        case 'rejected': return 'danger'
        case 'declined': return 'danger'
        case 'pending': return 'warning'
        default: return 'light'
      }
    }
  }
}
