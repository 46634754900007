import { BootstrapVue, NavbarPlugin, IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import VueMoment from 'vue-moment';
import VueSync from 'vue-sync';
import Router from 'vue-router';

import SlideLoader from './components/SlideLoader.vue';
import AxiosPlugin from './lib/axios';
import ToastPlugin from './lib/toast';
import VeeValidate from './lib/validator';
import Helpers from './lib/helpers';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VeeValidate, {
  locale: 'en',
  events: 'input',
  fieldsBagName: 'veeFields',
});
Vue.use(VueMoment);
Vue.use(VueSync);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(NavbarPlugin);
Vue.use(ToastPlugin);
Vue.use(AxiosPlugin);
Vue.use(Helpers);
Vue.use(Router);

Vue.component('SlideLoader', SlideLoader);

Vue.prototype.$clientAppUrl = process.env.VUE_APP_CLIENT_URL;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
