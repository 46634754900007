export default [
  {
    title: 'Dashboard',
    to: { name: 'dashboard' },
  },
  {
    title: 'Payouts',
    to: { name: 'payouts' },
  },
  {
    title: 'Users',
    to: { name: 'users' },
  },
  {
    title: 'Contests',
    to: { name: 'contests' },
  },
  {
    title: 'Entries',
    to: { name: 'entries' },
  },
  {
    title: 'Discounts',
    to: { name: 'discounts' },
  },
  {
    title: 'Industries',
    to: { name: 'industries' },
  },
  {
    title: 'TLDs',
    to: { name: 'tlds' },
  },
  {
    title: 'Logs',
    to: { name: 'logs' },
  },
];
