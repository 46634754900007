




































import Vue from 'vue';
import { USER_ROLES } from './../shared';

export default Vue.extend({
  name: 'Login',
  data: () => ({
    credits: {
      username: '',
      password: '',
    },
    appTitle: process.env.VUE_APP_TITLE,
    appVersion: process.env.VUE_APP_VERSION,
    isLoading: false,
  }),
  computed: {
    lang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    async submit() {
      const isInputsValid = await this.$validator
        .validateAll()
        .then(() => true)
        .catch(() => false);

      if (!isInputsValid) return;

      this.isLoading = true;

      this.$http
        .post('v1/admin/users/login/email', this.credits)
        .then(onReponse.bind(this))
        .catch(onError.bind(this));

      // @ts-ignore
      function onReponse({ access_token, user }) {
        if (!user.roles.includes(USER_ROLES.ROLE_ADMIN)) {
          throw new Error('This account should provide admin access.');
        }

        this.isLoading = false;
        this.$store.commit('SET_TOKEN', access_token);
        this.$store.commit('SET_ACCOUNT', user);

        this.$router.push({ name: 'dashboard' });
      }

      function onError(err) {
        this.isLoading = false;

        if (err.response && err.response.status === 401) {
          this.$toast.error('Error', 'Incorrect email or password.');
          return;
        }

        this.$toast.error('Error', err.message);
      }
    },
  },
});
