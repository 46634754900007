export enum USER_ROLES {
  'ROLE_ADMIN' = 'ROLE_ADMIN',
  'ROLE_ANON' = 'ROLE_ANON',
  'ROLE_CREATIVE_WRITER' = 'ROLE_CREATIVE_WRITER',
  'ROLE_CREATIVE_DESIGNER' = 'ROLE_CREATIVE_DESIGNER',
  'ROLE_CUSTOMER' = 'ROLE_CUSTOMER',
}

export function userIsCustomer(user: { roles: USER_ROLES[] }): boolean {
  if (!user.roles) {
    return false;
  }

  return user.roles.includes(USER_ROLES.ROLE_CUSTOMER);
}

export function userIsCreative(user: { roles: USER_ROLES[] }): boolean {
  if (!user.roles) {
    return false;
  }

  return (
    user.roles.includes(USER_ROLES.ROLE_CREATIVE_DESIGNER) ||
    user.roles.includes(USER_ROLES.ROLE_CREATIVE_WRITER)
  );
}

export function userIsWriter(user: { roles: USER_ROLES[] }): boolean {
  if (!user.roles) {
    return false;
  }

  return user.roles.includes(USER_ROLES.ROLE_CREATIVE_WRITER);
}

export function userIsDesigner(user: { roles: USER_ROLES[] }): boolean {
  if (!user.roles) {
    return false;
  }

  return user.roles.includes(USER_ROLES.ROLE_CREATIVE_DESIGNER);
}
