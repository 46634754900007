<template lang="pug">
  #industries
    router-view

    .view(v-if="showList")
      h2.mb-0.mt-1.mb-4 Industries
      .card
        slide-loader(:display="isLoading")
        .card-header
          .row
            .col-8
              button.btn.btn-success(@click="newIndustry") New
            .col-4
        .card-body.p-0
          table.table
            thead
              tr
                th Name
                th
            tbody
              tr(v-for="industry in displayList", :key="industry._id")
                td
                  router-link(:to="{name: 'industries.view', params: { id: industry._id }}") {{ industry.name }}
                  .small ID: {{ industry._id }}
                td.text-right
                  button.btn.btn-danger.btn-sm(:disabled="isLoading", @click="removeIndustry(industry)")
                    i.fa.fa-trash

        .card-footer
          local-pagination(:source-list="list", v-model="displayList")
</template>

<script>
import LocalPagination from '../components/LocalPagination';

export default {
  name: 'IndustriesPage',
  components: { LocalPagination },
  data: () => ({
    list: [],
    displayList: [],
    searchText: '',
    isLoading: false,
  }),
  computed: {
    showList() {
      return this.$route.name === 'industries';
    },
  },
  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
  },
  mounted() {
    if (this.showList) this.refresh();
    this.$store.dispatch('SET_LOADER_STATE', false);
  },
  methods: {
    newIndustry() {
      this.$router.push({ name: 'industries.new' });
    },
    removeIndustry(industry) {
      this.$bvModal
        .msgBoxConfirm(`Delete this industry`, { centered: true })
        .then(res => {
          if (!res) return;

          this.isLoading = true;
          this.$http
            .delete(`v1/admin/industries/${industry._id}`)
            .then(() => {
              this.$toast.success(
                'Complete',
                `${industry.name} successfully removed.`,
              );
              this.refresh();
            })
            .catch(error => {
              this.$toast.error('Industriy delete error', error.message);
              this.isLoading = false;
            });
        });
    },
    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/industries')
        .then(result => {
          this.list = result;
          this.isLoading = false;
        })
        .catch(error => {
          this.$toast.error('Industries fetching error', error.message);
          this.isLoading = false;
        });
    },
  },
};
</script>
