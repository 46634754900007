import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import defaultState from './default';

const VUE_APP_PERSISTED_STATE_KEY = process.env.VUE_APP_PERSISTED_STATE_KEY;
const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: require('./actions'),
  getters: {},
  mutations: require('./mutations'),
  strict: debug,
  plugins: [
    createPersistedState({
      key: VUE_APP_PERSISTED_STATE_KEY,
    }),
  ],
  state: defaultState,
});
