





























































































import moment from 'moment';

export default {
  name: 'ContestsPage',
  beforeRouteUpdate(to, from, next) {
    this.searchText = to.query.search || '';
    this.level = to.query.level || '';

    this.refresh();
    this.$store.dispatch('SET_LOADER_STATE', false);
    next();
  },

  data: () => {
    const todayMorning = moment().startOf('day');
    const todayEvening = moment().endOf('day');

    return {
      list: [],
      displayList: [],
      searchText: '',
      isLoading: false,
      level: '',

      perPage: 50,
      currentPage: 1,
      sortBy: 'timestamp',
      filterFromDate: todayMorning.format('YYYY-MM-DD'),
      filterFromTime: todayMorning.format('HH:mm:ss'),
      filterToDate: todayEvening.format('YYYY-MM-DD'),
      filterToTime: todayEvening.format('HH:mm:ss'),
      sortDesc: true,
      numberOfPages: 1,
      totalResults: 0,
      advanced: false,

      fields: [
        { key: 'label', formatter: (value, key, log) => log.meta.label },
        {
          key: 'timestamp',
          formatter: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          key: 'user',
          formatter: (value, key, log) => log.meta.authenticated_user,
        },
        {
          key: 'correlation_id',
          formatter: (value, key, log) => log.meta.correlation_id,
        },
        { key: 'level' },
        {
          key: 'message',
        },
        {
          key: 'meta',
          formatter: value => JSON.stringify(value),
        },
      ],
    };
  },
  url: {
    searchText: 'search',
    level: 'level',
    filterFromDate: 'fromDate',
    filterFromTime: 'fromTime',
    filterToDate: 'toDate',
    filterToTime: 'toTime',
  },

  computed: {
    showList() {
      return this.$route.name === 'logs';
    },
  },

  watch: {
    showList(newValue) {
      if (newValue) this.refresh();
    },
    sortBy() {
      this.refresh();
    },
    sortDesc() {
      this.refresh();
    },
  },

  mounted() {
    if (typeof this.$route.query.advanced !== 'undefined') {
      this.advanced = true;
    }
    if (this.$route.query.page) {
      this.currentPage = Number.parseInt(this.$route.query.page, 10) || 1;
    }
    if (this.showList) {
      this.refresh();
    }
    this.$store.dispatch('SET_LOADER_STATE', false);
  },

  methods: {
    linkGen(pageNum) {
      return {
        path: this.$route.path,
        query: Object.assign({}, this.$route.query, { page: pageNum }),
      };
    },

    refresh() {
      this.isLoading = true;

      this.$http
        .get('v1/admin/logs', {
          params: {
            search: this.searchText,
            order: 'desc',
            skip: (this.currentPage - 1) * this.perPage,
            limit: this.perPage,
            level: this.level,
            from: moment(
              this.filterFromDate + ' ' + this.filtrFromTime,
              'YYYY-MM-DD HH:mm:ss',
            )
              .utc()
              .toDate(),
            until: moment(
              this.filterToDate + ' ' + this.filterToTime,
              'YYYY-MM-DD HH:mm:ss',
            )
              .utc()
              .toDate(),
          },
        })
        .then(result => {
          this.list = result.items;
          this.numberOfPages = Math.floor(result.count / this.perPage + 1);
          this.isLoading = false;
          this.totalResults = result.count;
        })
        .catch(error => {
          this.$toast.error('Contests fetching error', error.message);
          this.isLoading = false;
        });
    },

    exportCSV() {
      return this.downloadFile('v1/admin/logs?format=csv', 'logs.csv', {
        params: {
          search: this.searchText,
          order: 'desc',
          level: this.level,
          from: moment(
            this.filterFromDate + ' ' + this.filtrFromTime,
            'YYYY-MM-DD HH:mm:ss',
          )
            .utc()
            .toDate(),
          until: moment(
            this.filterToDate + ' ' + this.filterToTime,
            'YYYY-MM-DD HH:mm:ss',
          )
            .utc()
            .toDate(),
        },
      });
    },
  },
};
