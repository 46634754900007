<template lang="pug">
	.container
		router-view
</template>

<script>
import '../scss/common.scss';

export default {
  name: 'CommonContainer',
};
</script>
