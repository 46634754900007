const UsersPage = r => require.ensure([], () => r(require('./UsersPage.vue')), 'users')
const UserView = r => require.ensure([], () => r(require('./UserView.vue')), 'users')
const UserNew = r => require.ensure([], () => r(require('./UserNew.vue')), 'users')

export const routes = [{
  path: '/users',
  name: 'users',
  component: UsersPage,
  meta: {
    title: 'Users',
    view: 'panel'
  },
  children: [{
    path: 'new',
    name: 'users.new',
    component: UserNew,
    meta: {
      title: 'New',
      view: 'panel'
    }
  }, {
    path: ':id',
    name: 'users.view',
    component: UserView,
    meta: {
      title: 'View',
      view: 'panel'
    }
  }]
}]
