<template lang="pug">
  form
    .row
      .col-12
        .form-group
          label Extension
          input.form-control(placeholder="", name="name", v-model="model.name", v-validate="'required'")
          .mt-2.text-danger(v-if="errors.has('name')")
            span {{ errors.first('name') }}

        .form-group
          label Price
          input.form-control(placeholder="", type="number", name="tldPrice", v-model="model.tldPrice", v-validate="'required'")
          .mt-2.text-danger(v-if="errors.has('price')")
            span {{ errors.first('price') }}

        .form-group
          b-form-checkbox(v-model="model.popular" name="check-button" switch) Popular
</template>

<script>
import _pick from 'lodash/pick';

const MODEL_FIELDS = ['name', 'price', 'popular'];

export default {
  name: 'TLDform',
  props: ['model'],

  methods: {
    validate() {
      this.model.price = Math.round(this.model.tldPrice * 100);

      return this.$validator
        .validateAll()
        .then(() => _pick(this.model, MODEL_FIELDS))
        .catch(() => false);
    },
  },
};
</script>
