<template lang="pug">
  div(class="bd-sidebar")
    b-nav(vertical)
      b-nav-item(v-for="item in list", :key="item.title")
        router-link(:to="item.to", tag="a", exact) {{ item.title }}

    .small.ml-4 v. {{ projectInfo.version }}
</template>

<script>
import nav from '../nav';

export default {
  name: 'Sidebar',
  components: {},
  data: () => ({
    list: nav,
    activeSubmenu: '',
  }),
  computed: {
    projectInfo() {
      return {
        name: process.env.VUE_APP_TITLE,
        version: process.env.VUE_APP_VERSION,
      };
    },
  },
  created() {
    window.addEventListener(`scroll`, this.handleScroll);
  },
  destroyed() {
    window.removeEventListener(`scroll`, this.handleScroll);
  },
  methods: {
    toggleDropdown(item) {
      this.activeSubmenu = this.activeSubmenu === item.title ? '' : item.title;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      this.paddingTop = Math.max(0, 70 - scrollTop);
    },
  },
};
</script>
