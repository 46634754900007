<template lang="pug">
  b-container
    b-row.mb-3
      b-col.mb-3
        b-card(title="General")
          date-range-picker(
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="true"
            @update="getGeneral"
            v-model="reportsRanges.general")
          table.table.table-sm.mt-3
            tbody
              tr(v-for="(row, index) in myCounts")
                td
                  b-icon(v-if="!!row.description", icon="info-circle", aria-label="Help", :id="'data_' + index")
                  b-tooltip(v-if="!!row.description", :target="'data_' + index", placement="right") {{ row.description }}
                td {{ row.name }}
                td.text-right {{ row.value }}
      b-col
        b-card(title="Contests per day")
          date-range-picker(
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="true"
            @update="getContestsReport"
            v-model="reportsRanges.contests")
          h-bar-chart(v-if="reports.contests !== null" :chart-data="reports.contests" :options="chartsOptions" )

    b-row.mb-3
      b-col
        b-card.mt-3(title="Payouts Per Day")
          date-range-picker(
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="true"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="true"
            @update="getPayoutsPerDay"
            v-model="reportsRanges.payoutsPerDay")

          table.table.table-sm.mt-3
            tbody
              tr(v-for="(row, index) in payoutsPerDay")
                td {{ row.date }}
                td.text-right ${{ Math.abs(row.amount) / 100 }}

</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import HBarChart from '../components/Charts/HorizontalBarChart';

export default {
  name: 'DashboardPage',
  components: { DateRangePicker, HBarChart },
  data: () => {
    return {
      myCounts: [],
      reports: {
        contests: null,
      },
      payoutsPerDay: [],
      revenue: [],
      reportsRanges: {
        contests: {
          startDate: moment().subtract(7, 'days').hour(12).toDate(),
          endDate: moment().hour(12).toDate(),
        },
        payoutsPerDay: {
          startDate: moment().startOf('month').hour(12).toDate(),
          endDate: moment().endOf('month').hour(12).toDate(),
        },
        general: {
          startDate: moment().hour(12).toDate(),
          endDate: moment().hour(12).toDate(),
        },
      },
      chartsOptions: { scales: { xAxes: [{ ticks: { stepSize: 1 } }] } },
    };
  },
  async mounted() {
    this.getGeneral();
    this.getContestsReport();
    this.getPayoutsPerDay();
    this.getRevenue();
  },
  methods: {
    async getGeneral() {
      const result = await this.$http.get('v1/admin/reports/general', {
        params: {
          from: this.reportsRanges.general.startDate,
          until: this.reportsRanges.general.endDate,
        },
      });

      this.myCounts = result;
    },
    async getPayoutsPerDay() {
      const result = await this.$http.get('v1/admin/reports/payouts-per-day', {
        params: {
          from: this.reportsRanges.payoutsPerDay.startDate,
          until: this.reportsRanges.payoutsPerDay.endDate,
        },
      });

      this.payoutsPerDay = result;
    },
    async getRevenue() {
      const result = await this.$http.get('v1/admin/reports/revenue', {
        params: {
          from: this.reportsRanges.payouts.startDate,
          until: this.reportsRanges.payouts.endDate,
        },
      });

      this.revenue = result;
    },
    async getContestsReport() {
      const result = await this.$http.get('v1/admin/reports/contests/short', {
        params: {
          from: this.reportsRanges.contests.startDate,
          until: this.reportsRanges.contests.endDate,
        },
      });

      this.reports = Object.assign(this.reports, {
        contests: {
          labels: Object.keys(result),
          datasets: [
            {
              label: 'Contests per day',
              data: Object.values(result),
            },
          ],
        },
      });

      console.log(this.reports);
    },
  },
};
</script>
