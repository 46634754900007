const DashboardPage = (r) =>
  require.ensure([], () => r(require('./DashboardPage.vue')), 'dashboard')

export const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    meta: {
      title: 'Dashboard',
      view: 'panel'
    }
  }
]
