const TLDsPage = r => require.ensure([], () => r(require('./TLDsPage.vue')), 'tlds')
const TLDview = r => require.ensure([], () => r(require('./TLDview.vue')), 'tlds')
const TLDnew = r => require.ensure([], () => r(require('./TLDnew.vue')), 'tlds')

export const routes = [{
  path: '/tlds',
  name: 'tlds',
  component: TLDsPage,
  meta: {
    title: 'Available TLDs',
    view: 'panel'
  },
  children: [{
    path: 'new',
    name: 'tlds.new',
    component: TLDnew,
    meta: {
      title: 'New',
      view: 'panel'
    }
  }, {
    path: ':id',
    name: 'tlds.view',
    component: TLDview,
    meta: {
      title: 'View',
      view: 'panel'
    }
  }]
}]
